import React from "react";
import { useEffect, useRef, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import {
  Button,
  CenteredHeader,
  DownloadAppBox,
  DownloadAppMini,
  DownloadAppRating,
  DownloadAppTopBanner,
  Header,
  HeaderBox,
  HeaderCenteredImgBox,
  HeaderHiddenBox,
  HeaderImgBox,
  HeaderPageTitle,
  MainHeader,
  MainHeaderTitle,
  MainHeaderDescription
} from "@brainfinance/icash-component-library";
import { MainHeaderProps } from "./header";
import { navigate } from "gatsby";

// images
import ios from "@images/iOS.svg";
import android from "@images/Android.svg";
import logo from "@images/icash-symbol-black-sized.svg";
import defaultHeader from "@images/fullscreen-background.jpg";

export const MainHeaderContainer = (props: React.PropsWithChildren<MainHeaderProps>) => {
  const [animation, setAnimation] = useState<"show" | "hide" | undefined>();
  const headerView = useRef<HTMLDivElement>(null);
  const [closed, setClosed] = useState(false);
  const handleClose = () => setClosed(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAnimation(entry.isIntersecting ? "hide" : "show");
      },
      { rootMargin: "0px" }
    );

    if (headerView?.current) observer.observe(headerView.current);

    return () => {
      if (headerView?.current) observer.unobserve(headerView.current);
    };
  }, []);

  return (
    <div className="mt-[-99px]">
      {closed ? null : (
        <DownloadAppTopBanner
          animate={animation}
          onClose={handleClose}
          iosLink="https://icash-ca.app.link/URLVQwAR9X"
          androidLink="https://icash-ca.app.link/7MQFYetR9X"
          iosImg={ios}
          androidImg={android}
        >
          Download the app and borrow up to $1,500
        </DownloadAppTopBanner>
      )}
      <MainHeader className={`${props.bgColor} sm:bg-white`} src={props.src} srcSet={props.srcSet} onClick={() => navigate('/login')} buttonText="24 hour service · Apply now">
        <MainHeaderTitle className="mb-[33px] md:mb-[16px]">{props.children}</MainHeaderTitle>
        <MainHeaderDescription>{props.supParagraph}</MainHeaderDescription>
        <div className="hidden sm:block rounded-[48px] bg-[#FFCEE7] my-[22px]">
          <img className="w-full h-full rounded-[48px]" src={props.src} srcSet={props.srcSet} />
        </div>
        <MainHeaderDescription className="mb-[22px] sm:mb-0">
          Available 24/7 accross Canada. Apply online or download our app to get started.
        </MainHeaderDescription>
        <Button
          appearance="primary"
          size="large"
          onClick={() => navigate('/login')}
          className="sm:hidden bottom-0 w-max mb-[88px] lg:mb-[66px]"
        >
          24 hour service · Apply now
        </Button>
        <div className="max-w-[410px] sm:hidden">
          <DownloadAppBox className="lg:mb-[270px] md:mb-0" darkBg={props.darkBg} downloadLink="https://icash-ca.app.link/7MQFYetR9X" iosLink="https://icash-ca.app.link/URLVQwAR9X" androidLink="https://icash-ca.app.link/7MQFYetR9X" iosImg={ios} androidImg={android}>
            {props.download ? props.download : "The iCash mobile app and borrow up to $1,500 anywhere, at any time."}
          </DownloadAppBox>
          <DownloadAppRating
            className="lg:hidden mb-[270px]"
            iosRating="7.3k"
            iosScore="4.8"
            androidRating="3.8k"
            androidScore="4.2"
          />
        </div>
      </MainHeader>
    </div>
  );
};

export const SideHeader = (props: React.PropsWithChildren<MainHeaderProps>) => {
  const [animation, setAnimation] = useState<"show" | "hide" | undefined>();
  const headerView = useRef<HTMLDivElement>(null);
  const [closed, setClosed] = useState(false);
  const handleClose = () => setClosed(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAnimation(entry.isIntersecting ? "hide" : "show");
      },
      { rootMargin: "0px" }
    );

    if (headerView?.current) observer.observe(headerView.current);

    return () => {
      if (headerView?.current) observer.unobserve(headerView.current);
    };
  }, []);

  return (
    <>
      {closed ? null : (
        <DownloadAppTopBanner
          animate={animation}
          onClose={handleClose}
          iosLink="https://icash-ca.app.link/URLVQwAR9X"
          androidLink="https://icash-ca.app.link/7MQFYetR9X"
          iosImg={ios}
          androidImg={android}
        >
          Download the app and borrow up to $1,500
        </DownloadAppTopBanner>
      )}
      <Header ref={headerView}>
        <div className="header--box">
          <HeaderPageTitle>{props.children}</HeaderPageTitle>
          <HeaderBox subParagraph={props.supParagraph}>{props.description}</HeaderBox>
          <div className="header--download-app-box">
            <DownloadAppBox downloadLink="https://icash-ca.app.link/7MQFYetR9X" iosLink="https://icash-ca.app.link/URLVQwAR9X" androidLink="https://icash-ca.app.link/7MQFYetR9X" iosImg={ios} androidImg={android}>
              {props.download ? props.download : "The iCash mobile app and borrow up to $1,500 anywhere, at any time."}
            </DownloadAppBox>
            <DownloadAppRating iosRating="7.3k" iosScore="4.8" androidRating="3.8k" androidScore="4.2" />
          </div>
        </div>
        <HeaderImgBox src={props.src} srcSet={props.srcSet} logoBoxImg={logo} mediumImg={props.mediumImg}>
          <DownloadAppMini
            osType={isIOS ? "ios" : isAndroid ? "android" : ""}
            iosLink="https://icash-ca.app.link/URLVQwAR9X"
            androidLink="https://icash-ca.app.link/7MQFYetR9X"
            iosImg={ios}
            androidImg={android}
          >
            Download the app and borrow up to $1,500
          </DownloadAppMini>
        </HeaderImgBox>
        <HeaderHiddenBox buttonOff={props.buttonOff} buttonText={props.buttonText} buttonOnClick={() => {}}>
          {props.subParagraph}
        </HeaderHiddenBox>
      </Header>
    </>
  );
};

MainHeaderContainer.defaultProps = {
  src: defaultHeader,
  mediumImg: false,
  description:
    "My description Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam, recusandae praesentium eveniet iste ea ipsam necessitatibus libero soluta. Beatae possimus asperiores veniam molestiae provident commodi natus eius exercitationem tempora ea.",
  children: (
    <>
      <span className="text-interface-300">Instant loans</span>
      <br />
      approved in 5 minutes for Canadians
    </>
  )
};


export const CenterHeader = (props: React.PropsWithChildren<MainHeaderProps>) => (
  <CenteredHeader>
    <div className="header--centered-container-box">
      <h1 className="global--page-heading mb-[1.75rem]">
        {props.children}
      </h1>
      <p className="global--paragraph text-interface-300">
        {props.description}
      </p>
    </div>
    <HeaderCenteredImgBox src={props.src} srcSet={props.srcSet}>
      <DownloadAppBox downloadLink="https://icash-ca.app.link/7MQFYetR9X" iosLink="https://icash-ca.app.link/URLVQwAR9X" androidLink="https://icash-ca.app.link/7MQFYetR9X" iosImg={ios} androidImg={android} overlay>
        {props.download ? props.download : "The iCash mobile app and borrow up to $1,500 anywhere, at any time."}
      </DownloadAppBox>
    </HeaderCenteredImgBox>
  </CenteredHeader>
);

CenterHeader.defaultProps = {
  src: defaultHeader,
  description:
    "My description Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam, recusandae praesentium eveniet iste ea ipsam necessitatibus libero soluta. Beatae possimus asperiores veniam molestiae provident commodi natus eius exercitationem tempora ea.",
  children: (
    <>
      <span className="text-interface-300">Instant loans</span>
      <br />
      approved in 5 minutes for Canadians
    </>
  )
};